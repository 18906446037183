import React from "react"
import classNames from "classnames"

import styles from "./index.module.scss"

const PageCover = ({ image, noFilter, title }) => {
  const coverClass = classNames({
    [styles.cover]: true,
    [styles.noFilter]: noFilter,
  })

  return (
    <div className={coverClass}>
      {image}
      {title && <h1>{title}</h1>}
    </div>
  )
}

export default PageCover
