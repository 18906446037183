import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import Slider from "react-slick"

import styles from "../styles/freguesia.module.scss"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import Layout from "../components/Layout"
import PageCover from "../components/PageCover"

const Freguesia = () => {
  const data = useStaticQuery(graphql`
    query {
      getCoverImage: file(relativePath: { eq: "freguesia/cover.png" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      getSliderImages: allFile(
        filter: { relativePath: { regex: "/slider/" } }
      ) {
        edges {
          node {
            id
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  const settings = {
    accessibility: true,
    dots: true,
    fade: true,
    arrows: false,
    autoplay: true,
  }

  return (
    <Layout>
      <PageCover
        image={
          <Img
            fluid={data.getCoverImage.childImageSharp.fluid}
            alt="Fachada de casa na freguesia do Touro"
            style={{ height: "100%", width: "100%" }}
            imgStyle={{ objectFit: "cover" }}
          />
        }
        title="A Freguesia"
      />
      <div className={styles.wrapper}>
        <div className={styles.description}>
          <h1>Touro, um retalho da Beira Alta</h1>
          <p>
            A freguesia é todo um corredor natural, atravessado pelo rio Côvo,
            que até 800 do século passado se apelidou de Touro, constelação
            surgida na antiga via romana, que levava para Lamego, cruzando
            caminhos e povoações. Adomingueiros, terra fértil de moinhos.
            Hidráulica e serranias, nas Faldas da Nave, soberania da natureza,
            numa paisagem que atravessa todo o planalto. Freguesia de corgos,
            que inundam os campos e provêm lameiros, onde apascenta gado e se
            irriga a agricultura dos vales. Terra de oráculos e capelas, de
            dólmenes e arqueologia, terra antiga de amor, que ergueu santuário
            ao Senhor da Boa Sorte e escadaria de granito que sobe até à gigante
            cruz, onde pousa Nossa Senhora, também ela da Boa Sorte. A Póvoa,
            lugar de agricultura e de pastorícia, a Cerdeira, das cerejas,
            Avesseira, do mel, Viduinho da agricultura e da Anta de grande
            dimensões que simboliza o povo antigo que aqui chegou há mais de 5
            mil anos,
          </p>
        </div>
        <div className={styles.imageCaptionWrapper}>
          <div className={styles.image}>
            <Slider {...settings}>
              {data.getSliderImages.edges.map(img => (
                <Img
                  key={img.node.id}
                  fluid={img.node.childImageSharp.fluid}
                  alt="Freguesia do Touro"
                  style={{ maxHeight: "100%" }}
                />
              ))}
            </Slider>
          </div>
          <div className={styles.captionWrapper}>
            <div className={styles.caption}>
              <h1>
                “Touro, carácter serrano, águas de mil e uma nascentes, toda uma
                Beira num ritual de passagem.”
              </h1>
            </div>
          </div>
        </div>
        <div className={styles.description}>
          <h1>Natureza e tradição</h1>
          <p>
            Terras altas, de mais de 800 metros de altitude, toda a freguesia é
            marcada pelo granito e pelo Côvo, rio de moinhos e regadio, terras
            de tojo e carqueja, de pinheiros e soutos, que abrigam perdizes e
            patos bravos, lebres e javalis, por entre campos de milho e centeio.
            A ternura do granito, a secura do planalto, a enxuteza das gentes,
            essa vontade que renasce na mão da miudagem. É freguesia grande, de
            enormes tradições, de culto religioso, como a Santo Antão, a São
            Francisco, a Santo António, ao Senhor do Amparo.
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default Freguesia
